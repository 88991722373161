import { Flex, P, Text } from '@updater/ui-uds';
import React from 'react';
import { StreamCard } from 'flows/core/components/cards/common/stream-card';
import { PromoPayload } from 'flows/core/types/cards';
import {
  LogoBarImage,
  LogoBarImageContainer,
} from 'flows/core/components/cards/common/logo-bar';
import { PromoInsideContainer } from 'flows/core/components/cards/common/promo-inside-container';

export const SYIPromoCard: React.FC<PromoPayload> = ({
  data,
  identifier,
  type,
}) => {
  if (data.subtype !== 'SYI') return null;

  const { title, image, action, icon, cost, badge, description } = data;

  return (
    <StreamCard
      icon={icon}
      heading={title}
      badge={badge}
      identifier={identifier}
      action={action}
      cardType={type}
    >
      <PromoInsideContainer type="COLOR">
        <Flex
          flexDirection="column"
          justifyContent={cost ? 'space-evenly' : 'center'}
          alignItems="flex-start"
          padding="s"
          height="100%"
        >
          {image.url && (
            <LogoBarImageContainer key={image.alt}>
              <LogoBarImage src={image.url} alt={image.alt} blend />
            </LogoBarImageContainer>
          )}
          {cost && (
            <Flex flexDirection="column">
              <P variant="s">Starting at</P>
              <Flex alignItems="flex-end">
                <P variant="xxxl">${cost}</P>
                <P mb="xs" variant="m">
                  /mo
                </P>
              </Flex>
            </Flex>
          )}
          {description && (
            <Text variant="s" mt="xs">
              {description}
            </Text>
          )}
        </Flex>
      </PromoInsideContainer>
    </StreamCard>
  );
};
