import {
  TvInternetOffer,
  useTvInternetOfferLazyQuery,
  useTvInternetReservedOfferLazyQuery,
} from '@updater/consumer-graph';
import { useEffect, useMemo } from 'react';

export function useTVIOfferData(moveId: string, status: 'reserved' | unknown) {
  const [queryAvailableOffers, availableOffersQuery] =
    useTvInternetOfferLazyQuery();
  const [queryReservedOffer, reservedOfferQuery] =
    useTvInternetReservedOfferLazyQuery();

  useEffect(() => {
    if (moveId) {
      status === 'reserved'
        ? queryReservedOffer({ variables: { moveId } })
        : queryAvailableOffers({ variables: { input: { moveId } } });
    }
  }, [moveId, status]);

  const queries = [availableOffersQuery, reservedOfferQuery];
  return useMemo(() => {
    const offers = <TvInternetOffer[]>[
      ...(availableOffersQuery.data?.availableOffers?.offers ?? []),
      undefined,
    ];

    const cheapestOffer = offers.reduce((cheapest, contender) =>
      (cheapest?.pricing?.price ?? 0) < (contender?.pricing?.price ?? 0)
        ? cheapest
        : contender
    );

    const fastestOffer = offers.reduce((fastest, contender) =>
      (fastest?.products?.internet?.downloadSpeed?.value ?? 0) >
      (contender?.products?.internet?.downloadSpeed?.value ?? 0)
        ? fastest
        : contender
    );

    const reservedOffer =
      reservedOfferQuery.data?.homeServicesReservation?.reservation?.offer;

    const includesInternetOffers = offers.some(
      (offer) => offer?.products?.internet?.downloadSpeed != null
    );

    return {
      called: queries.some((v) => v.called),
      loading: queries.some((v) => v.loading),
      error: queries.find((v) => Boolean(v.error)),
      data: {
        unit: fastestOffer?.products?.internet?.downloadSpeed?.unit ?? 'mbps',
        providerImage: reservedOffer?.provider,
        includesInternetOffers,
        ...(includesInternetOffers
          ? {
              speed: fastestOffer?.products?.internet?.downloadSpeed?.value,
              price: cheapestOffer?.pricing?.price,
            }
          : {
              speed: reservedOffer?.services.internet?.speed?.mbps,
              price: Number(reservedOffer?.price?.toFixed(2)),
            }),
      },
    };
  }, queries);
}
