import React from 'react';
import styled from '@emotion/styled';
import { Flex, Text, Image } from '@updater/ui-uds';
import { StreamCard } from 'flows/core/components/cards/common/stream-card';
import { LogoBar } from 'flows/core/components/cards/common/logo-bar';

import { PromoPayload, TviPromoSubtype } from 'flows/core/types/cards';
import { SpinnerText } from 'components/spinner-text';
import { TVIPrice } from 'flows/core/components/cards/tvi-promo-card/components/tvi-price';
import { useTVIOfferData } from 'flows/core/hooks/use-tvi-card-data';
import { PromoInsideContainer } from 'flows/core/components/cards/common/promo-inside-container';
import { TVISpeed } from './components/tvi-speed';

export interface TVIPromoCardProps {
  loadingMessage?: string;
}

const ImageWrapper = styled(Image)`
  width: 114px;
  padding-bottom: 8px;
`;

export const TVIPromoCard: React.FC<PromoPayload & TVIPromoCardProps> = ({
  data,
  identifier,
  type,
  loadingMessage = 'Gathering available plans...',
}) => {
  const { moveId, status } = data as TviPromoSubtype;
  const {
    loading,
    called,
    error,
    data: tviData,
  } = useTVIOfferData(moveId, status);

  if (data.subtype !== 'TVI') return null;

  const { title, action, icon, badge, providers } = data;
  const { providerImage, includesInternetOffers } = tviData || {};

  if (
    !loading &&
    !error &&
    tviData &&
    (includesInternetOffers || providerImage?.logo)
  ) {
    const { unit, speed, price } = tviData;
    const isOffer = !providerImage?.logo;
    return (
      <StreamCard
        icon={icon}
        heading={title}
        action={action}
        badge={badge}
        identifier={identifier}
        cardType={type}
      >
        <PromoInsideContainer type="COLOR">
          <Flex
            flexDirection="column"
            justifyContent="center"
            p="s"
            height="100%"
          >
            <>
              {!isOffer && (
                <ImageWrapper
                  src={providerImage?.logo}
                  alt={providerImage?.name}
                />
              )}
              <Text variant="s">
                {isOffer ? 'As fast as' : 'Internet plan'}
              </Text>
              <Flex alignItems="" flexDirection="column">
                {speed && (
                  <TVISpeed
                    isOffer={isOffer}
                    unit={unit}
                    // note the error case here
                    // — we're defaulting to zero values
                    price={price ?? 0}
                    speed={speed ?? 0}
                  />
                )}
                {typeof price === 'number' && (
                  <>
                    {isOffer && (
                      <Text variant="s" mt="xs">
                        Starting at
                      </Text>
                    )}
                    <TVIPrice price={price} isOffer={isOffer} />
                  </>
                )}
              </Flex>
            </>
          </Flex>
        </PromoInsideContainer>
      </StreamCard>
    );
  }
  return (
    <StreamCard
      icon={icon}
      action={action}
      heading={title}
      badge={badge}
      identifier={identifier}
      cardType={type}
    >
      <PromoInsideContainer type="COLOR">
        <Flex flexDirection="column" justifyContent="center" height="100%">
          {error ? (
            <>
              <LogoBar images={providers} />
              <Text p="1rem">We're having trouble finding your offers.</Text>
            </>
          ) : !called || loading ? (
            <SpinnerText message={loadingMessage} />
          ) : (
            <LogoBar images={providers} />
          )}
        </Flex>
      </PromoInsideContainer>
    </StreamCard>
  );
};
