import React from 'react';
import { useTracking } from 'react-tracking';
import { TrackEventInput } from '@updater/ui-tracker';
import { CardData, CardDataWithHeroExtras } from 'flows/core/types/cards';
import { TVIPromoCard } from 'flows/core/components/cards/tvi-promo-card';
import { FYMPromoCard } from 'flows/core/components/cards/fym-promo-card';
import { SYIPromoCard } from 'flows/core/components/cards/syi-promo-card';
import { UTILSPromoCard } from 'flows/core/components/cards/utils-promo-card';
import { ImagePromoCard } from 'flows/core/components/cards/image-promo-card';
import { InitiateCard } from 'flows/core/components/cards/initiate-card';
import { BasicReceiptCard, ReceiptCheckBackLaterCard } from '../receipt-card';
import { TviReceiptCard } from '../receipt-card/TviReceiptCard';

type SingleCardProps = {
  card: CardData | CardDataWithHeroExtras;
  position?: number | string;
};

const CardComponentMapper: React.VFC<SingleCardProps> = ({ card }) => {
  switch (card.type) {
    case 'PROMOTED':
      switch (card.data.subtype) {
        case 'TVI':
          return <TVIPromoCard {...card} />;
        case 'FYM':
          return <FYMPromoCard {...card} />;
        case 'SYI':
          return <SYIPromoCard {...card} />;
        case 'UTIL':
          return <UTILSPromoCard {...card} />;
        case 'IMAGE':
          return <ImagePromoCard {...card} />;
        default:
          return null;
      }
    case 'INITIATE':
      return <InitiateCard {...card} />;
    case 'RECEIPT':
      switch (card.data.subtype) {
        case 'BASIC':
          return <BasicReceiptCard {...card} />;
        case 'CHECKBACKLATER':
          return <ReceiptCheckBackLaterCard {...card} />;
        case 'TVI':
          return <TviReceiptCard {...card} />;
        default:
          return null;
      }
    default:
      return null;
  }
};

export const SingleCard: React.VFC<SingleCardProps> = ({ card, position }) => {
  const { Track } = useTracking<TrackEventInput<unknown>>({
    object: 'card',
    details: { card, position },
  });
  return (
    <Track>
      <CardComponentMapper card={card} />
    </Track>
  );
};
