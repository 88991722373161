import React from 'react';
import { Flex, Spinner, Text } from '@updater/ui-uds';

type SpinnerTextProps = {
  message: string;
};

export const SpinnerText: React.FC<SpinnerTextProps> = ({ message }) => (
  <Flex
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    padding="m"
  >
    <Spinner size="s" data-testid="loading-spinner" />
    <Text mt="m" variant="xs">
      {message}
    </Text>
  </Flex>
);
