import {
  Badge,
  Box,
  Button,
  Flex,
  H2,
  P,
  Palette,
  Text,
  TextVariantKey,
} from '@updater/ui-uds';
import {
  BadgeConfig,
  CardTarget,
  CardTypes,
  IconName,
} from 'flows/core/types/cards';

import React, { PropsWithChildren, ReactElement, ReactNode } from 'react';

import Link from 'next/link';
import { TrackEventInput } from '@updater/ui-tracker';
import { getIconComponent } from 'utils/get-icon';
import styled from '@emotion/styled';
import { useTracking } from 'react-tracking';

import { CardWrapper } from '../card-wrapper';

export interface StreamCardProps {
  icon?: ReactElement | IconName;
  heading: string;
  identifier: string;
  headingVariant?: TextVariantKey | TextVariantKey[];
  description?: string;
  badge?: BadgeConfig;
  ctaText?: string;
  action?: CardTarget;
  descriptionBottom?: boolean;
  cardType: CardTypes;
  descriptionLine2?: string;
  subtitle?: string;
  actionTracker?: () => void | undefined;
  children?: ReactNode;
  ctaFluidWidth?: boolean;
}

const ContentWrapper = styled.div`
  margin-top: ${({ theme }) => `${theme.space.s}px`};
`;

const Anchor = styled(Link)`
  text-decoration: none;
  ${({ theme }) => theme.mediaQueries.l} {
    &:hover {
      &:after {
        opacity: 1;
        transition: opacity 0.15s ease-in-out;
      }
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: transparent;
    border: 1px solid ${Palette.royalBlue};
    border-radius: ${({ theme }) => theme.radii.s};
    transition: opacity 0.15s ease-in-out;
    z-index: 0;
  }
`;

export const ActionHandler: React.FC<
  PropsWithChildren<{
    action?: CardTarget;
    actionTracker?: () => void | undefined;
  }>
> = ({ action, actionTracker, children }) => {
  const { trackEvent } = useTracking<TrackEventInput<unknown>>({});
  if (action) {
    const url =
      action?.type === 'internal'
        ? { pathname: action.route, query: action.params }
        : action?.url;

    return (
      <Anchor
        href={url}
        passHref
        onClick={() =>
          actionTracker ? actionTracker() : trackEvent({ verb: 'clicked' })
        }
      >
        {children}
      </Anchor>
    );
  }
  return <>{children}</>;
};

export const StreamCard: React.FC<StreamCardProps> = ({
  icon,
  heading,
  headingVariant = 'l',
  description,
  badge,
  identifier,
  ctaText,
  action,
  descriptionBottom = false,
  cardType,
  descriptionLine2,
  subtitle,
  actionTracker,
  children,
  ctaFluidWidth,
}) => {
  const childs = React.Children.toArray(children).filter(Boolean);
  const isInitiate = cardType === 'INITIATE';

  const iconComponent =
    typeof icon === 'string' ? getIconComponent(icon) : icon;
  if (cardType === 'RECEIPT') {
    return (
      <CardWrapper
        padding="16px 24px"
        type={cardType}
        border="none"
        data-testid={`stream-card-receipt-${identifier}`}
        data-cy={`stream-card-receipt-${identifier}`}
      >
        <ActionHandler action={action} actionTracker={actionTracker}>
          <Flex flexDirection="column" height="100%">
            <Flex alignItems="center">
              {badge && <Badge width="fit-content" {...badge} />}
            </Flex>
            <Box flexGrow={1}>
              <H2 mt="xs" variant="sBold">
                {heading}
              </H2>
              {description && <Text variant="s">{description}</Text>}
              {descriptionLine2 && <Text variant="s">{descriptionLine2}</Text>}
            </Box>

            {subtitle && (
              <Text as="p" variant="xs" color={Palette.gray50}>
                {subtitle}
              </Text>
            )}
          </Flex>
        </ActionHandler>
      </CardWrapper>
    );
  }

  return (
    <CardWrapper
      data-testid={`stream-card-${identifier}`}
      data-cy={`stream-card-${identifier}`}
      type={cardType}
      border="none"
      padding="0"
    >
      <ActionHandler action={action}>
        <Box height="100%">
          <Flex flexDirection="column" height="100%">
            <Flex
              flexDirection="column"
              height="100%"
              paddingTop="l"
              paddingBottom="l"
              paddingLeft="m"
              paddingRight="m"
            >
              <Flex alignItems="center">
                {iconComponent}
                {badge && <Badge width="fit-content" ml="xs" {...badge} />}
              </Flex>
              <Box flexGrow={1}>
                <H2 mt="xs" variant={headingVariant}>
                  {heading}
                </H2>
                {!descriptionBottom && description && (
                  <P
                    variant="s"
                    mt="xxs"
                    color={isInitiate ? Palette.gray50 : Palette.darkBlue}
                  >
                    {description}
                  </P>
                )}
              </Box>
              {childs.length > 0 && <ContentWrapper>{childs}</ContentWrapper>}
              {descriptionBottom && description && (
                <P variant="m" mt="xxs">
                  {description}
                </P>
              )}
              {ctaText && action && (
                <Box>
                  <Button isFluidWidth={ctaFluidWidth} mt="s">
                    {ctaText}
                  </Button>
                </Box>
              )}
            </Flex>
          </Flex>
        </Box>
      </ActionHandler>
    </CardWrapper>
  );
};
