import React, { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { ImageData } from 'flows/core/types/cards';
import { Box, Image, ColorKey } from '@updater/ui-uds';

const CONTAINER_HEIGHT = '234px';

type BackgroundTypes = 'IMAGE' | 'COLOR';

type PromoInsideContainerSubtype = {
  type: BackgroundTypes;
};

type ColorData = {
  color?: ColorKey;
  borderDashed?: boolean;
};

export interface ImageSubtype extends PromoInsideContainerSubtype {
  type: 'IMAGE';
  data: ImageData;
  isBoxes: boolean;
}

export interface ColorSubtype extends PromoInsideContainerSubtype {
  type: 'COLOR';
  data?: ColorData;
}

const ImageContainer = styled(Box)`
  height: ${CONTAINER_HEIGHT};
  overflow: hidden;
`;

export const PromoInsideContainer: React.FC<
  PropsWithChildren<ImageSubtype | ColorSubtype>
> = ({ type, data, children, ...props }) => {
  if (type === 'IMAGE') {
    const { isBoxes } = props as ImageSubtype;
    // we have bg only for ROB Promo cards
    const { url, alt, bg } = data;
    return (
      <ImageContainer
        position="relative"
        borderRadius="xs"
        backgroundColor={bg}
      >
        <Image
          height="100%"
          minWidth="240px"
          maxWidth={bg ? '100%' : '360px'}
          width="100%"
          objectFit={isBoxes ? 'contain' : 'cover'}
          src={url}
          alt={alt}
          borderRadius="xs"
          position="absolute"
          top="0"
          left="0"
        />
        {children}
      </ImageContainer>
    );
  }
  if (type === 'COLOR') {
    const { color, borderDashed } = data || {};
    return (
      <Box
        backgroundColor={color ?? 'reviewCardBackground'}
        borderRadius="xs"
        height={CONTAINER_HEIGHT}
        width="100%"
        borderStyle={borderDashed ? 'dashed' : undefined}
        borderColor={borderDashed ? 'disabled' : undefined}
      >
        {children}
      </Box>
    );
  }
  return null;
};
