import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
  Text,
  Flex,
  TextLink,
  Button,
  Palette,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalTitle,
} from '@updater/ui-uds';
import { MapPin } from '@phosphor-icons/react';
import {
  AddressLineProps,
  ExtraContentData,
  PromoPayload,
} from 'flows/core/types/cards';
import { StreamCard } from 'flows/core/components/cards/common/stream-card';
import { PromoInsideContainer } from 'flows/core/components/cards/common/promo-inside-container';
import { getIconComponent } from 'utils/get-icon';

const ExtraContentWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  background-color: rgba(255, 255, 255, 0.5);
  padding: ${({ theme }) => theme.space.s}px;
`;

const IconWrapper = styled(MapPin)`
  margin: auto 0;
`;

const AddressLineContainer = styled(Flex)`
  &:first-of-type {
    margin-bottom: ${({ theme }) => `${theme.space.xs}px`};
  }
`;

export const AddressLine: React.FC<AddressLineProps> = ({ point, address }) => {
  if (!address) {
    return null;
  }
  return (
    <AddressLineContainer>
      <IconWrapper color={Palette.royalBlue} size={16} weight="fill" />
      <Text variant="xs" ml="xs">
        {point}: {address}
      </Text>
    </AddressLineContainer>
  );
};

export const ExtraContentItem: React.VFC<ExtraContentData> = ({
  type,
  content,
}) => {
  switch (type) {
    case 'AddressLine':
      return <AddressLine {...content} />;
    default:
      return null;
  }
};

export const ImagePromoCard: React.FC<PromoPayload> = ({
  data,
  identifier,
  type,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (data.subtype !== 'IMAGE') return null;

  const {
    title,
    action,
    description,
    icon,
    badge,
    showDiscard,
    image,
    extraContent,
  } = data;

  const handleDraft = () => {
    setIsModalOpen(false);
  };

  return (
    <StreamCard
      heading={title}
      description={description}
      icon={getIconComponent(icon, `${identifier}-icon`)}
      action={action}
      badge={badge}
      identifier={identifier}
      cardType={type}
    >
      <PromoInsideContainer
        type="IMAGE"
        data={{ url: image.url, alt: image.alt, bg: image.bg }}
        isBoxes={icon === 'BOX'}
      >
        {extraContent && (
          <ExtraContentWrapper>
            {extraContent.map(({ key, ...extraContentItem }) => (
              <ExtraContentItem {...extraContentItem} key={key} />
            ))}
          </ExtraContentWrapper>
        )}
      </PromoInsideContainer>
      {showDiscard && (
        <TextLink
          as="p"
          color={Palette.royalBlue}
          mt="s"
          variant="s"
          onClick={() => setIsModalOpen(true)}
        >
          Discard draft
        </TextLink>
      )}
      <Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen} renderAsChild>
        <ModalOverlay />
        <ModalContent
          borderRadius="xs"
          maxWidth="350px"
          width="100%"
          minWidth="1"
          maxHeight="250px"
          minHeight="1"
        >
          <ModalHeader>
            <ModalTitle>Discard Draft</ModalTitle>
            <ModalCloseButton />
          </ModalHeader>

          <ModalBody flexDirection="column">Are you sure?</ModalBody>
          <ModalFooter justifyContent="space-between">
            <Button variant="secondary" onClick={() => setIsModalOpen(false)}>
              Cancel
            </Button>
            <Button onClick={handleDraft} variant="primary" disabled={false}>
              Yes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </StreamCard>
  );
};
