import { ReceiptPayload } from 'flows/core/types/cards';
import { StreamCard } from '../common/stream-card';

export type BasicReceiptCardProps = ReceiptPayload & {
  showSubtitle?: boolean;
  actionTracker?: () => void | undefined;
};

export const BasicReceiptCard: React.FC<BasicReceiptCardProps> = ({
  data,
  identifier,
  type,
  showSubtitle = false,
  actionTracker,
  // importanceScore,
}) => {
  if (data.subtype !== 'BASIC') return null;
  const { heading, description, descriptionLine2, subtitle, badge, action } =
    data;
  return (
    <StreamCard
      identifier={identifier}
      cardType={type}
      heading={heading}
      description={description}
      descriptionLine2={descriptionLine2}
      subtitle={showSubtitle ? subtitle : ''}
      badge={badge}
      action={action}
      actionTracker={actionTracker}
    />
  );
};
