import React, { FC } from 'react';
import { useTracking } from 'react-tracking';
import { TrackEventInput } from '@updater/ui-tracker';
import {
  BadgeConfig,
  CardData,
  CardTarget,
  InitiatePayload,
  IconName,
} from 'flows/core/types/cards';
import {
  CardV2,
  CardFooter,
  CardFooterRight,
  Box,
  H1,
  Button,
  Text,
  Badge,
  Flex,
} from '@updater/ui-uds';
import {
  TabLayoutGrid,
  TabLayoutGridProps,
} from 'flows/core/components/tab-layout-grid';

import { Tab } from 'components/nav-bar/NavBar';
import { SingleCard } from 'flows/core/components/cards/single-card';
import { getIconComponent } from 'utils/get-icon';
import { useRouter } from 'next/router';

/* Copy and button text for experiments */
// only used on when new card with footer is used
const BUTTON_TEXT = {
  TVI: 'See options',
  FYM: 'Submit your info',
  SYI: 'See options',
  UTIL: 'See options',
  BOXES: 'See options',
  ROB: '', // We should not ever display, but needs to be here for type
};

// only used when new copy is to be displayed
const NEW_TITLES = {
  TVI: 'Stay connected',
  FYM: 'Get your mail',
  SYI: 'Protect all of your things',
  UTIL: 'Keep the lights on',
  BOXES: 'Get boxes and supplies',
  ROB: '', // We should not ever display, but needs to be here for type
};

// only used when new copy is to be displayed
const NEW_DESCRIPTIONS = {
  TVI: 'Setup internet and/or TV at your next place so your ready day 1',
  FYM: 'Update your address with USPS so your mail doesn`t get lost in the shuffle',
  SYI: 'Get renter`s insurance and get peace of mind that your things are covered',
  UTIL: 'Get electric transfered or explore other provider options at your new address',
  BOXES: 'Free shipping on moving kits with all the packing materials you need',
  ROB: '', // Will never display this, but needs to be here for type
};

/* Properties */
interface TabLayoutCardV2LayoutProps extends TabLayoutGridProps {
  data: CardData[];
  currentTab?: Tab;
  section?: string;
}

interface NewCardProps {
  identifier?: string;
  icon: IconName;
  badge?: BadgeConfig;
  heading: string;
  description: string;
  buttonText: string;
  onButtonClick?: () => void;
}

/* Composed components */
export const NewCardComponent: FC<NewCardProps> = ({
  identifier,
  icon,
  badge,
  heading,
  description,
  buttonText,
  onButtonClick,
}) => {
  return (
    <CardV2 variant="rounded" data-testid={`cardV2-${identifier}`}>
      <Box padding="m">
        <Flex alignItems="center">
          {getIconComponent(icon)}
          {badge && (
            <Badge data-testid="badge" width="fit-content" ml="xs" {...badge} />
          )}
        </Flex>
        <H1 mt="xs" variant="l" data-testid="cardV2-heading">
          {heading}
        </H1>
        <Text variant="s" data-testid="cardV2-description">
          {description}
        </Text>
      </Box>
      <CardFooter variant="box">
        <CardFooterRight>
          <Button
            data-testid={`footer-button-${identifier}`}
            onClick={onButtonClick}
            variant="primary"
            size="s"
          >
            {buttonText}
          </Button>
        </CardFooterRight>
      </CardFooter>
    </CardV2>
  );
};

/* Rendered components */
export const TabLayoutCardV2Layout: React.FC<TabLayoutCardV2LayoutProps> = ({
  title,
  gridTemplateColumns,
  data,
  currentTab,
  section,
  gridGap,
}) => {
  const router = useRouter();
  const { Track, trackEvent } = useTracking<TrackEventInput<unknown>>({
    details: {
      section,
    },
  });

  // If data is buggy, show error message
  if (!Array.isArray(data)) {
    return <div>Incorrect data!</div>;
  }

  // Sets anything discarded (discard type card) in essentials
  data.map((card) => {
    const cardCopy = { ...card };

    if ('showDiscard' in cardCopy.data) {
      cardCopy.data.showDiscard = currentTab === 'essentials';
    }

    return cardCopy;
  });

  /* Functions */
  const handleClick = (action: CardTarget) => {
    if (action.type === 'internal') {
      trackEvent({
        verb: 'clicked',
        object: 'card',
        details: {
          clickedRoute: action.route,
          cardDisplay: 'cardV2Footer',
        },
      });
      router.push(action.route);
    }
  };

  // Builds the appropriate component for variation
  const buildCard = (
    card: InitiatePayload,
    variation: string,
    index: number
  ) => {
    const { icon, title: heading, badge, action, description } = card.data;

    // if this is of ROB type, early return nothing
    if (card.data.subtype === 'ROB') {
      return undefined;
    }

    // Returns standard card with new copy
    if (variation === 'newcopy') {
      // Make clone of card and update with new text
      const updatedCard = {
        ...card,
        data: {
          ...card.data,
          title: NEW_TITLES[card.data.subtype],
          description: NEW_DESCRIPTIONS[card.data.subtype],
        },
      } as InitiatePayload;

      return (
        <SingleCard
          data-testid={`card-${card.identifier}-${variation}`}
          card={updatedCard}
          position={index}
          key={updatedCard.identifier}
        />
      );
    }
    if (variation === 'newcard') {
      return (
        <NewCardComponent
          data-testid="new-card-component"
          key={card.identifier}
          identifier={card.data.subtype}
          icon={icon}
          badge={badge}
          heading={heading}
          description={description}
          buttonText={BUTTON_TEXT[card.data.subtype]}
          onButtonClick={() => handleClick(action)}
        />
      );
    }
    if (variation === 'newcardcopy') {
      return (
        <NewCardComponent
          data-testid="new-card-component"
          key={card.identifier}
          identifier={card.data.subtype}
          icon={icon}
          badge={badge}
          heading={NEW_TITLES[card.data.subtype]}
          description={NEW_DESCRIPTIONS[card.data.subtype]}
          buttonText={BUTTON_TEXT[card.data.subtype]}
          onButtonClick={() => handleClick(action)}
        />
      );
    }
    return undefined;
  };

  return (
    <Track>
      <TabLayoutGrid
        data-testid="experiment-tablayout-grid"
        title={title}
        gridTemplateColumns={gridTemplateColumns}
        gridGap={gridGap}
      >
        {data.map((card, index) => (
          <SingleCard
            data-testid={`card-${card.data.subtype}-control`}
            card={card}
            position={index}
            key={card.identifier}
          />
        ))}
      </TabLayoutGrid>
    </Track>
  );
};
