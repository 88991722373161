import styled from '@emotion/styled';
import { Text, Palette } from '@updater/ui-uds';
import { ReceiptPayload } from 'flows/core/types/cards';
import { ReceiptCard } from './ReceiptCard';

const Description = styled(Text)`
  width: 100%;
`;

export const ReceiptCheckBackLaterCard: React.FC<ReceiptPayload> = ({
  data,
  identifier,
  type,
  importanceScore,
}) => {
  if (data.subtype === 'CHECKBACKLATER') {
    return (
      <ReceiptCard
        data={data}
        identifier={identifier}
        type={type}
        importanceScore={importanceScore}
      >
        <Description as="p" variant="s" color={Palette.blue}>
          {data.titleLine1}
        </Description>
      </ReceiptCard>
    );
  }
  return null;
};
