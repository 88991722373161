import { FC, PropsWithChildren } from 'react';
import { Badge, Flex, Box, Palette } from '@updater/ui-uds';
import { ArrowSquareOut } from '@phosphor-icons/react';
import { ReceiptPayload } from 'flows/core/types/cards';
// import { getIconComponent } from 'utils/get-icon';
import { buildURL } from 'flows/core/hooks/use-data-for-cards';
import Link from 'next/link';
import { CardWrapper } from '../common/card-wrapper';

export const ReceiptCard: FC<PropsWithChildren<ReceiptPayload>> = ({
  data: { badge, action },
  children,
  identifier,
}) => {
  // const iconComponent = getIconComponent(icon);
  const linkOut = action && action.type === 'external';
  const link = action ? buildURL(action) : '';

  return (
    <CardWrapper
      padding="16px 24px"
      variant="rounded"
      data-testid={`receipt-card-${identifier}`}
    >
      <Link passHref href={link}>
        <Box data-test-id="receipt-action">
          <Flex justifyContent="flex-start" alignItems="center">
            {/* commenting out icon for MVP launch */}
            {/* <Box paddingRight="s" data-testid="icon">
              {iconComponent}
            </Box> */}
            <Flex flexDirection="column" width="100%">
              {badge ? <Badge width="fit-content" {...badge} /> : null}
              <Flex
                alignItems="flex-start"
                justifyContent="space-between"
                mt="xs"
              >
                <Flex flexDirection="column">{children || null}</Flex>
                {linkOut && (
                  <a
                    href={action.url}
                    target="_blank"
                    style={{ alignSelf: 'flex-start' }}
                    rel="noreferrer"
                  >
                    <ArrowSquareOut size={18} color={Palette.blue} />
                  </a>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Link>
    </CardWrapper>
  );
};
