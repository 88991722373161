import React from 'react';
import styled from '@emotion/styled';
import { Box, Flex, P, TextLink, Palette } from '@updater/ui-uds';
import { StreamCard } from 'flows/core/components/cards/common/stream-card';
import { PromoPayload } from 'flows/core/types/cards';
import { PromoInsideContainer } from '../common/promo-inside-container';

export type AddressType = {
  name: string;
  line1: string;
  line2?: string;
};

const ImgWrapper = styled.img`
  width: 68px;
`;

export const FYMPromoCard: React.FC<PromoPayload> = ({
  data,
  identifier,
  type,
}) => {
  if (data.subtype !== 'FYM') return null;

  const { address, title, image, action, icon, badge, showDiscard } = data;

  const handleClick = () => {
    console.log('draft discarded');
  };

  const addressLine = address?.line2
    ? `${address.line1}, #${address.line2}`
    : address.line1;

  return (
    <StreamCard
      heading={title}
      icon={icon}
      badge={badge}
      action={action}
      identifier={identifier}
      cardType={type}
    >
      <PromoInsideContainer type="COLOR" data={{ borderDashed: true }}>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
          padding="s"
          height="100%"
        >
          <ImgWrapper src={image.url} alt={image.alt} />
          <Box mt="xs">
            <P variant="sBold">{address.name}</P>
            <P variant="s">{addressLine}</P>
            <P variant="s">
              {address.city}, {address.state} {address.zipcode}
            </P>
          </Box>
        </Flex>
      </PromoInsideContainer>
      {showDiscard && (
        <TextLink
          color={Palette.royalBlue}
          mt="s"
          variant="s"
          onClick={handleClick}
        >
          Discard draft
        </TextLink>
      )}
    </StreamCard>
  );
};
