import { CardV2 } from '@updater/ui-uds';
import styled from '@emotion/styled';
import { CardTypes } from 'flows/core/types/cards';

type CardWrapperAttributes = {
  padding?: string;
  variant?: string;
  type?: CardTypes;
};

export const CardWrapper = styled(CardV2)<CardWrapperAttributes>`
  padding: ${({ theme, padding }) =>
    padding ?? `${theme.space.l}px ${theme.space.m}px`};
  width: 100%;
  background-color: ${({ theme }) => theme.colors.secondary};
  position: relative;
`;

CardWrapper.defaultProps = {
  variant: 'rounded',
};
