import styled from '@emotion/styled';
import { Flex, Text } from '@updater/ui-uds';
import { getNumberDigitsLength } from '../tvi-price/TVIPrice';

type TVISpeedProps = {
  price: number;
  speed: number;
  unit: string;
  isOffer?: boolean;
};
type UnitProps = {
  priceLength: number;
};

export const SpeedUnits = styled(Text)<UnitProps>`
  line-height: ${({ theme, priceLength }) =>
    priceLength > 4 ? theme.lineHeights.xl : theme.lineHeights.xxl};
`;

export const TVISpeed: React.FC<TVISpeedProps> = ({
  price,
  speed,
  unit,
  isOffer = true,
}) => {
  const priceLength = getNumberDigitsLength(price);
  const speedVariant = priceLength <= 3 && isOffer ? 'xxxl' : 'xxl';
  const unitVariant = priceLength > 4 ? 'm' : 'l';
  return (
    <Flex alignItems="flex-end">
      <Text variant={speedVariant} data-testid="speed">
        {speed}
      </Text>
      <SpeedUnits
        priceLength={priceLength}
        variant={unitVariant}
        data-testid="units"
      >
        {unit}
      </SpeedUnits>
    </Flex>
  );
};
