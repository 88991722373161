import React from 'react';
import { StreamCard } from 'flows/core/components/cards/common/stream-card';
import { PromoPayload } from 'flows/core/types/cards';
import { getIconComponent } from 'utils/get-icon';
import { LogoBar } from 'flows/core/components/cards/common/logo-bar';

const testId = 'utils-promo-card-icon';

export const UTILSPromoCard: React.FC<PromoPayload> = ({
  data,
  identifier,
  type,
}) => {
  if (data.subtype !== 'UTIL') return null;

  const { icon, title, action, badge } = data;

  return (
    <StreamCard
      icon={getIconComponent(icon, testId)}
      heading={title}
      badge={badge}
      identifier={identifier}
      action={action}
      cardType={type}
    >
      {data.providers ? <LogoBar images={data.providers} blend /> : null}
    </StreamCard>
  );
};
