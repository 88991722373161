import { IconName } from 'flows/core/types/cards';
// import { theme } from '@updater/ui-uds';
import {
  ArrowsDownUp,
  CalendarBlank,
  Car,
  CloudArrowDown,
  CurrencyCircleDollar,
  Dog,
  EnvelopeSimple,
  FileText,
  Key,
  Lightbulb,
  Link,
  Package,
  PawPrint,
  Plus,
  Receipt,
  Shield,
  Truck,
  Wallet,
  WifiHigh,
  Garage,
  CarProfile,
  Person,
  MapPinLine,
  Recycle,
  Buildings,
} from '@phosphor-icons/react';

export const ICON_COMPONENT_MAPPING = {
  ENVELOPE: EnvelopeSimple,
  WIFI: WifiHigh,
  TRUCK: Truck,
  BOX: Package,
  SHIELD: Shield,
  LIGHT: Lightbulb,
  CAR: Car,
  DOG: Dog,
  DOLLAR: CurrencyCircleDollar,
  PLUS: Plus,
  KEY: Key,
  CALENDAR: CalendarBlank,
  LINK: Link,
  FILE: FileText,
  DOWNLOAD: CloudArrowDown,
  RECEIPT: Receipt,
  WALLET: Wallet,
  ARROWS_DOWN_UP: ArrowsDownUp,
  PAW: PawPrint,
  GARAGE: Garage,
  CAR_PROFILE: CarProfile,
  PERSON: Person,
  MAP_PIN: MapPinLine,
  RECYCLE: Recycle,
  BUILDING: Buildings,
} as const;
export const getIconComponent = (icon: IconName, testId?: string) => {
  const IconComponent = ICON_COMPONENT_MAPPING[icon];
  if (IconComponent) {
    return (
      <IconComponent
        data-testid={testId}
        color="#62A0F8" // TODO: for some reason adding this to the colorKey isn't building properly
        size={32}
        weight="thin"
      />
    );
  }
  return null;
};
