import { TrackEventInput } from '@updater/ui-tracker';
import {
  TabLayoutGrid,
  TabLayoutGridProps,
} from 'flows/core/components/tab-layout-grid';
import { CardData } from 'flows/core/types/cards';
import React from 'react';
import { useTracking } from 'react-tracking';

import { SingleCard } from 'flows/core/components/cards/single-card';

interface TabLayoutCartLayoutProps extends TabLayoutGridProps {
  data: CardData[] | undefined;
  section?: string;
}

export const TabLayoutCardLayout: React.FC<TabLayoutCartLayoutProps> = ({
  title,
  gridTemplateColumns,
  data,
  section,
  gridGap,
}) => {
  const { Track } = useTracking<TrackEventInput<unknown>>({
    details: {
      section,
    },
  });
  if (!Array.isArray(data)) {
    return <div>Incorrect data!</div>;
  }

  return (
    <Track>
      <TabLayoutGrid
        title={title}
        gridTemplateColumns={gridTemplateColumns}
        gridGap={gridGap}
      >
        {data.map((card, index) => {
          return (
            <SingleCard card={card} position={index} key={card.identifier} />
          );
        })}
      </TabLayoutGrid>
    </Track>
  );
};
