import React, { PropsWithChildren } from 'react';
import { Flex, Text, Grid } from '@updater/ui-uds';

export interface TabLayoutGridProps {
  title?: string | React.ReactNode;
  gridTemplateColumns: Array<any>;
  gridGap?: Array<any>;
}

export const TabLayoutGrid: React.FC<PropsWithChildren<TabLayoutGridProps>> = ({
  children,
  title,
  gridTemplateColumns,
  gridGap = ['xl', 'xl', 'm', 'm'],
}) => {
  return (
    <Flex flexDirection="column">
      {title && (
        <Text variant="lBold" marginBottom="m" as="h2">
          {title}
        </Text>
      )}
      <Grid
        gridGap={gridGap}
        gridTemplateColumns={gridTemplateColumns}
        data-testid="card-layout-container"
      >
        {children}
      </Grid>
    </Flex>
  );
};
