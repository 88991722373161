import React from 'react';
import styled from '@emotion/styled';
import { Flex, Image } from '@updater/ui-uds';
import { PromoInsideContainer } from '../promo-inside-container';

interface LogoImage {
  url: string;
  alt: string;
}

type LogoBarProps = {
  images?: Array<LogoImage>;
  blend?: boolean;
};

interface LogoBarImageProps {
  blend?: boolean;
}
interface LogoBarContainerProps {
  maxHeight?: string;
  maxWidth?: string;
}

export const LogoBarImage = styled(Image)<LogoBarImageProps>`
  height: 100%;
  max-width: 100%;
  object-fit: contain;
  mix-blend-mode: ${(props) => (props.blend ? 'multiply' : 'unset')};
`;

export const LogoBarImageContainer = styled.div<LogoBarContainerProps>`
  max-width: 140px;
  max-height: 40px;
`;

export const LogoBar: React.FC<LogoBarProps> = ({ images, blend = false }) => {
  return images == null ? null : (
    <PromoInsideContainer type="COLOR">
      <Flex
        flexDirection="column"
        justifyContent="space-evenly"
        alignItems="flex-start"
        padding="s"
        height="100%"
      >
        {images.slice(0, 3).map((image) => (
          <LogoBarImageContainer
            key={image.alt}
            data-testid="logo-bar-logo-container"
          >
            <LogoBarImage src={image.url} alt={image.alt} blend={blend} />
          </LogoBarImageContainer>
        ))}
      </Flex>
    </PromoInsideContainer>
  );
};
