import React from 'react';
import { StreamCard } from 'flows/core/components/cards/common/stream-card';
import { InitiatePayload } from 'flows/core/types/cards';
import { getIconComponent } from 'utils/get-icon';
import { Badges } from 'flows/core/utils/card-makers/utils';
import { useFeature } from '@growthbook/growthbook-react';

const testId = 'initiate-card-icon';

export const InitiateCard: React.FC<InitiatePayload> = ({
  data,
  identifier,
}) => {
  const { on: requiredBadgeEnabled } = useFeature('essentials_required_badge');
  const { icon, title, badge: cardBadge, action, description, required } = data;

  return (
    <StreamCard
      icon={getIconComponent(icon, testId) ?? <></>}
      heading={title}
      description={description}
      badge={
        required && !cardBadge && requiredBadgeEnabled
          ? Badges.REQUIRED
          : cardBadge
      }
      identifier={identifier}
      cardType="INITIATE"
      action={action}
      headingVariant="l"
    />
  );
};
