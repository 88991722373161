import { Flex, Palette, Text } from '@updater/ui-uds';
import { ReceiptPayload } from 'flows/core/types/cards';
import { ReceiptCard } from '.';

export const TviReceiptCard: React.FC<ReceiptPayload> = ({
  type,
  data,
  identifier,
  importanceScore,
}) => {
  if (data.subtype === 'TVI') {
    const {
      details,
      details: { speed, price, provider },
      subtitle,
    } = data;
    return (
      <ReceiptCard
        data={data}
        type={type}
        identifier={identifier}
        importanceScore={importanceScore}
      >
        {details ? (
          <Flex flexDirection="row" justifyContent="space-between" flex="1">
            {speed && (
              <Flex flexDirection="column">
                <Text variant="mBold">{speed}</Text>
                <Text variant="s">mbps</Text>
              </Flex>
            )}
            {price && (
              <Flex flexDirection="column">
                <Text variant="mBold">{price}</Text>
                <Text variant="s">/mo</Text>
              </Flex>
            )}
            {provider && (
              <Flex flexDirection="column" paddingRight={['s']}>
                <Text variant="mBold">{provider}</Text>
                <Text variant="s">Internet + TV</Text>
              </Flex>
            )}
          </Flex>
        ) : null}
        {subtitle ? (
          <Text as="p" variant="xs" color={Palette.gray50}>
            {subtitle}
          </Text>
        ) : null}
      </ReceiptCard>
    );
  }
  return null;
};
