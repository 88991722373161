import styled from '@emotion/styled';
import {
  Text,
  Flex,
  theme,
  TextVariantKey,
  useViewportSizes,
} from '@updater/ui-uds';
import React, { useMemo } from 'react';

export const getNumberDigitsLength = (num?: number | string) =>
  num?.toString().split('.').join('').length ?? 0;

type TVIPriceProps = {
  price: number | string;
  isOffer?: boolean;
};

export const UnitWrapper = styled(Text)<TVIPriceProps>`
  line-height: ${(props) =>
    getNumberDigitsLength(props?.price) >= 4
      ? props.theme.lineHeights.xl
      : props.theme.lineHeights.xxl};
  ${(props) => props.theme.mediaQueries.l} {
    line-height: ${(props) => props.theme.lineHeights.xxl};
  }
`;

export const TVIPrice: React.FC<TVIPriceProps> = ({
  price,
  isOffer = true,
}) => {
  const { isMedium } = useViewportSizes();
  const variants = useMemo(() => {
    let priceSize: TextVariantKey = isOffer ? 'xxxl' : 'xxl';
    let mounthSize: TextVariantKey = 'l';
    const priceLength = getNumberDigitsLength(price);
    if (priceLength >= 4 && isMedium) {
      if (isOffer) priceSize = 'xxl';
      if (priceLength >= 5) mounthSize = 'm';
    }

    return { priceSize, mounthSize };
  }, [price, isMedium]);

  return (
    <Flex alignItems="flex-end" {...(!isOffer && { mt: 'xs' })}>
      <Text
        data-testid="tvi-price"
        variant={variants.priceSize}
        color={theme.colors.primary}
      >
        ${Number(price).toFixed(2)}
      </Text>
      <UnitWrapper
        data-testid="tvi-price-unit"
        price={price}
        variant={variants.mounthSize}
        color={theme.colors.primary}
      >
        /mo
      </UnitWrapper>
    </Flex>
  );
};
